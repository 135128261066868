import { Entity, EntityBuilder } from '@jobilla/entity';
import { AuthenticationCode } from './authentication-code.enum';

export class AuthenticationResponse extends Entity {
  public code: string = null;
  public token: string = null;

  public constructor(initData?: Partial<AuthenticationResponse>) {
    super();
    EntityBuilder.fill(this, initData as any);
  }

  public is2FA(): boolean {
    return this.code === AuthenticationCode.MultiFactorAuthentication;
  }

  public isLoggedIn(): boolean {
    return this.code === AuthenticationCode.LoggedIn && Boolean(this.token);
  }
}
